<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else>
		<FormKTCForeverPlan
			is-edit
			:is-submitting="plan.edit.isUpdating"
			:group-id="$route.params.id"
			:method-id="$route.params.methodId"
			:ktc-product-id="data.ktcProductId"
			:name="data.name"
			:rate="data.rate"
			:discount-percent="data.discountPercent"
			@onSubmit="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormKTCForeverPlan from '@/components/FormKTCForeverPlan.vue';

export default {
	name: 'KTCForeverPlanEdit',

	components: {
		FormKTCForeverPlan,
	},

	data() {
		return {
			isLoading: false,
		};
	},

	computed: {
		...mapState('payments', {
			plan: 'ccForeverPlan',
		}),

		...mapGetters({
			data: 'payments/CCForeverDetails',
		}),
	},

	async created() {
		this.isLoading = true;

		await this.getPlan({
			methodId: this.$route.params.methodId,
			planId: this.$route.params.planId,
		});

		this.isLoading = false;

		// Set breadcrumb
		const bankName = this.data.paymentMethod.name;
		this.setBreadcrumbs([
			{
				title: 'Payment settings',
				route: { name: 'PaymentList' },
			},
			{
				title: `Credit Card / Debit Card / ${bankName}`,
				route: {
					name: 'PaymentMethodSetting',
					params: { id: this.$route.params.id, methodId: this.$route.params.methodId },
				},
			},
		]);
	},

	methods: {
		...mapActions({
			getPlan: 'payments/getCCForeverPlan',
			updatePlan: 'payments/updateCCForeverPlan',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			const planId = this.$route.params.planId;

			await this.updatePlan({
				methodId,
				planId,
				params,
			});
		},
	},
};
</script>
